let cancelAnimation = null
let animate = null

if (process.browser) {
  animate = (function(){
    const requestAnimationFrame = window.requestAnimationFrame || 
                                  window.mozRequestAnimationFrame ||
                                  window.webkitRequestAnimationFrame ||
                                  window.msRequestAnimationFrame;
    return function(cb){
        const myRef = { req: null };
        myRef.req = requestAnimationFrame(function fn(){
          cb();
          myRef.myReq = requestAnimationFrame(fn);
        });
        return myRef
    }
  })();
  cancelAnimation =  window.cancelAnimationFrame || window.mozCancelAnimationFrame;
}
export  {
  animate, 
  cancelAnimation
}