import { render, staticRenderFns } from "./newHome_v2.vue?vue&type=template&id=7f68f42d&scoped=true&"
import script from "./newHome_v2.vue?vue&type=script&lang=js&"
export * from "./newHome_v2.vue?vue&type=script&lang=js&"
import style0 from "./newHome_v2.vue?vue&type=style&index=0&id=7f68f42d&prod&lang=scss&"
import style1 from "./newHome_v2.vue?vue&type=style&index=1&id=7f68f42d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f68f42d",
  null
  
)

export default component.exports