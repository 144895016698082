
import  { animate,  cancelAnimation } from '@/utils/animate.js'
export default {
  props: {
    gutter: {
      type: Number,
      default: () => -40
    },
    row: {
      type: Number,
      default: () => 2
    },
    itemWidth: {
      type: Number,
      default: () => 206
    },
    list: {
      type: Array,
      default: () => []
    },
    hoverStop: {
      type: Boolean,
      default: () => true
    },
    itemClass: {
      type: String,
      default: () => ''
    },
    isOffset: {
      type: Boolean,
      default: () => true
    },
    minVirtualNum: {
      type: Number,
      default: () => 8
    }

  },
  data () {
    return {
      moveWidth: 0,
      stop: false,
      virtualNum: 10,
      isLoad: false,
      rate: 0,
    }
  },
  computed: {
    moveStyle () {
      return {
        transform: `translate3d(-${this.moveWidth}px, 0, 0)`
      }
    },
    column () {
      return Math.ceil(this.list.length / this.row)
    }
  },
  mounted () {
    this.rate = 1 // document.body.offsetWidth  / (1920 - this.getScrollWidth())
    this.virtualNum = Math.max(Math.ceil(this.$refs.container.offsetWidth / this.itemWidth * this.rate), this.minVirtualNum) 
    console.log( this.virtualNum )
    this.$nextTick(() => {
      this.isLoad = true
      this.superSlide()
    })
  },
  methods: {
    superSlide () {
      // 错位距离
      const gutter = this.gutter * this.rate
      // 位移需要的距离
      const width = this.$refs.realDom.clientWidth
      const v = 0.7
      const animateFn = () => {
        if (!this.stop) this.moveWidth += v
        if (this.moveWidth >= width) {
          this.moveWidth = 0
        }
      }
      if (this.isOffset) {
        this.$refs.container.style.marginLeft =  `-${this.$refs.virtualPre.offsetWidth}px`
        this.$refs.virtualPre.style.marginRight = `-${gutter}px`
        this.$refs.virtualNext.style.marginLeft= `-${gutter}px`
      } 
      const $evenDom  = document.querySelectorAll('.bottom-row:nth-child(2n)')
      $evenDom.forEach(item => {
        item.style.marginLeft = `${gutter}px`
      })
      const myRef = animate(animateFn, 16)
      this.$on('hook:onBeforeDestroy', () => cancelAnimation(myRef.req))
    },
  },
  watch: {
    list () {
      this.virtualNum = Math.ceil(this.$refs.container.offsetWidth / this.itemWidth)
      this.$nextTick(() => {
        this.superSlide()
      })
    },
  }
}
